body,
#root,
#__next {
  min-height: 100vh;
  min-height: 100dvh;
  min-width: auto; /* Remove tamagui's css reset setting min-width to 100vw, as that's causing horizontal scrolling when vertical scrollbar is present */
  width: 100vw;
  width: 100dvw;
  max-width: 100%; /* Handle vertical scroll bar adding to width */
  margin: 0;
}

/* This overrides a buggy transition in the Intercom chat window. The original
transition also includes 'width 200ms', which has been removed here.
See https://gitlab.com/cxnpl/webapp/-/merge_requests/897 for more context.*/

#intercom-container > .intercom-app > .intercom-messenger-frame {
  transition:
    height 200ms,
    max-height 200ms,
    transform 300ms cubic-bezier(0, 1.2, 1, 1),
    opacity 83ms ease-out !important;
}
