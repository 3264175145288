/*
 * CXNPL fonts
 * Use absolute paths for referencing fonts files from the root of the domain which points to the next/app/public directory
 * e.g. next/app/public/fonts/font.ttf => /fonts/font.ttf => my-bank.com.au/fonts/font.ttf
 */

@font-face {
  font-family: 'Poppins-Regular';
  src: url(/fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Italic';
  src: url(/fonts/Poppins-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url(/fonts/Poppins-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-MediumItalic';
  src: url(/fonts/Poppins-MediumItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url(/fonts/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBoldItalic';
  src: url(/fonts/Poppins-SemiBoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url(/fonts/Poppins-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-BoldItalic';
  src: url(/fonts/Poppins-BoldItalic.ttf) format('truetype');
}
