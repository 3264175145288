/*
 * GSB Fonts
 * Use absolute paths for referencing fonts files from the root of the domain which points to the next/app/public directory
 * e.g. next/app/public/fonts/font.ttf => /fonts/font.ttf => my-bank.com.au/fonts/font.ttf
 */

@font-face {
  font-family: 'Hellix-SemiBold';
  src: url(/fonts/Hellix-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'TTNormsPro-Medium';
  src: url(/fonts/TTNormsPro-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'TTNormsPro-MediumItalic';
  src: url(/fonts/TTNormsPro-MediumItalic.otf) format('opentype');
}

@font-face {
  font-family: 'TTNormsPro-Regular';
  src: url(/fonts/TTNormsPro-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'TTNormsPro-Italic';
  src: url(/fonts/TTNormsPro-Italic.otf) format('opentype');
}

@font-face {
  font-family: 'TTNormsPro-Bold';
  src: url(/fonts/TTNormsPro-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'TTNormsPro-BoldItalic';
  src: url(/fonts/TTNormsPro-BoldItalic.otf) format('opentype');
}
